.Button {
  margin: 5px;
}

.AlertSlug {
  margin: 5px;
}

.colorPicker {
  position: absolute;
  z-index: "2";
  margin-top: 10px;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}
